import React from 'react';
import currentUser from '../../HOCs/currentUser.hoc';
import BatchCallComponent from './batcCallWS.component';

const BatchCallLabsPage = () => {
  return (
    <div className="main-content mt-5">
      <div className="row text-muted"><h3>Batch of Cals v5 (11Labs)</h3></div>
      <div className="row bg-white p-2 h90p">
        <BatchCallComponent className="call-button-icon mb-1" provider="labs" />
      </div>
      <div className="empty-bottom-block" />
    </div>
  );
};

export default currentUser(BatchCallLabsPage);
