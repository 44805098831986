import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { InfoIcon, DiagramIcon } from '../../common/icons';
import TextareaField from '../../common/forms/textarea.form';
import models from './models.json';

const LlamaComponent = () => {
  const [supportedModels] = useState(models);
  const [defaultModels, setDefaultModel] = useState(JSON?.parse(localStorage.getItem('llama-model')) || models[0]);
  const [template, setTemplate] = useState('');
  const [userResponses, setUserResponses] = useState('');
  const [llamaResponse, setLlamaResponses] = useState(null);
  const [isLoading, setLoading] = useState(false);

  const onChangeModel = (e) => {
    const { value } = e.target;
    const { id } = e.target.selectedOptions[0];
    setDefaultModel({ name: id, model: value });
    localStorage.setItem('llama-model', JSON.stringify({ name: id, model: value }));
  }

  const onChangeTemplate = (e) => {
    setTemplate(e.target.value);
  }

  const onChangeUserResponses = (e) => {
    setUserResponses(e.target.value);
  }

  const sendToLamma = async () => {
    try {
      setLoading(true);
      setLlamaResponses(null);
      const response = await fetch('https://callolive.com/v1.0/training/llama/answer', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ model: defaultModels?.model, template, userResponses }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error: ${response.status}`);
      }

      const data = await response.json();
      console.log('Response:', data);
      let combineresponse = '';
      data.forEach((item, i) => {
        if (i !== 0) {
          combineresponse += `<strong>${item?.role}</strong>: ${item?.content}\n`
        }
      });
      setLlamaResponses(combineresponse);
    } catch (error) {
      console.error('Ошибка вызова API:', error);
    } finally {
      setLoading(false);
    }
  }

  return (
    <div className="speech-container">
      <div className="bot-select mt-1 mb-2"><span className='text-muted'>Model:</span>
        <select className="form-select w240 bot-select ms-2" onChange={onChangeModel} value={defaultModels?.lang}>
          {supportedModels?.map(model => (
            <option key={model.model} id={model.name} value={model.model}>
              {model.name}
            </option>
          ))}
        </select>
      </div>

      <div className="bot-select textarea-demo-call">
        <TextareaField
          label="Template: "
          name="data"
          value={template}
          onChange={onChangeTemplate}
          minHeight="200px"
          maxHeight="250px"
        />
      </div>

      <div className="bot-select textarea-demo-call">
        <TextareaField
          label="User responses: "
          name="data"
          value={userResponses}
          onChange={onChangeUserResponses}
          minHeight="200px"
          maxHeight="250px"
        />
      </div>

      <div className=" mt-2">
        <Button
          variant="primary"
          className="model-button"
          onClick={sendToLamma}
          disabled={!template?.length || !userResponses?.length || isLoading}
        >
          <DiagramIcon className="ml-1 call-button-icon" />{isLoading ? 'Loading...' : 'Send to Llama'}
        </Button>
      </div>

      <div className="mt-2"><span className='text-muted'>Message history:</span>
        <div
        className="large-text-container"
        dangerouslySetInnerHTML={{ __html: (!llamaResponse && isLoading) ? '...' : (!llamaResponse && !isLoading) ? 'no data': llamaResponse }} />
      </div>

      <br />
      <br />
      <div className="p-0 mb-1">
        <p><InfoIcon /> This page is intended for testing/editing the template and checking/training the Llama model.</p>
        <p><InfoIcon /> First, select a model and add a template. Then write the user's answers in order. Each new answer should be on a new line (use Enter to wrap to a new line).</p>
        <p><InfoIcon /> Then click the "Send to Llama" button and wait for a response. Check the response, if something is wrong - change the template and try again.</p>
      </div>
    </div>
  );
};

export default LlamaComponent;
