import React from 'react';
import { useSelector } from 'react-redux';
import { BugIcon } from '../../common/icons';

const NotFoundPage = () => {
  const userID = useSelector((state) => state?.auth?.userID);

  return (
    <div className={userID ? "main-content mt-5" : "main-content-for-404 mt-5"}>
      <div className="row text-muted">
        <h3><BugIcon /> Page Not Found</h3>
      </div>
      <div className="row bg-white pt-2 h500 d-flex justify-content-center align-items-center">
        <img
          src="/404page.jpg"
          alt="404 page illustration"
          style={{ maxWidth: '500px', marginTop: '20px' }}
        />
        <span className="d-flex justify-content-center align-items-center mb-4">The page you are looking for does not exist or has been moved. <br /> Please check the URL, or return to the homepage.</span>
      </div>

      <div className="empty-bottom-block" />
    </div>
  );
};

export default NotFoundPage;
