import React, { useState, useRef } from 'react';
import { Button } from 'react-bootstrap';
import { SaveFlIcon, UploadIcon, InfoIcon } from '../../common/icons';
import TextareaField from '../../common/forms/textarea.form';

const STTComponent = () => {
  const [audio, setAudio] = useState(null);
  const [template, setTemplate] = useState(null);
  const [correctTemplate, setCorrectTemplate] = useState(null);

  const [isUploadAudioFile, setUploadAudioFile] = useState(false);
  const [isUploadData, setUploadData] = useState(false);

  const fileInputRef = useRef(null);

  const onChangeTemplate = (e) => {
    setCorrectTemplate(e.target.value);
  }

  const handleFileChange = async (event) => {
    await handleUpload(event.target.files[0])
  };

  const handleUpload = async (selectedFile) => {
    setAudio(null);
    setTemplate(null);
    setCorrectTemplate(null);
    try {
      setUploadAudioFile(true);
      if (!selectedFile) {
        console.error('No file selected');
        return;
      }

      setAudio(selectedFile);

      const formData = new FormData();
      formData.append('audio_file', selectedFile);
      const response = await fetch('https://callolive.com/v1.0/training/stt/get-transcription', {
        method: 'POST',
        body: formData,
      });

      if (!response.ok) {
        throw new Error(`HTTP error: ${response.status}`);
      }
      const data = await response.json();

      setTemplate(data?.transcription);

      let transcriptionText = '';
      data?.transcription?.forEach(item => {
        transcriptionText += `${item.text}\n`
      });

      setCorrectTemplate(transcriptionText);
    } catch (error) {
      console.error('Error uploading file:', error);
    } finally {
      setUploadAudioFile(false)
    }
  };

  const sendData = async () => {
    try {
      setUploadData(true);
      if (!audio || !template || !correctTemplate) {
        console.error('Incomplete data.');
        return;
      }

      const correctTemplateArray = correctTemplate?.split(/\r?\n/).map(line => line.trim()).filter(line => line.length > 0);

      let formatedTemplate = '';
      let formatedCurrentTemplate = '';

      template.forEach((item, i) => {
        formatedTemplate += `${item?.start};${item?.end}; ${item?.text?.split()}${(template?.length-1) !== i ?'\n' : ''}`;
        formatedCurrentTemplate += `${item?.start};${item?.end}; ${correctTemplateArray[i]?.split()}${(template?.length-1) !== i ? '\n' : ''}`;
      });

      const formData = new FormData();
      formData.append('audio_file', audio);
      formData.append('initText', formatedTemplate);
      formData.append('correctText', formatedCurrentTemplate);

      const response = await fetch('https://callolive.com/v1.0/training/stt/save-files', {
        method: 'POST',
        body: formData,
      });

      if (!response.ok) {
        throw new Error(`HTTP error: ${response.status}`);
      }

      const result = await response.json();
      console.log('Data successfully sent:', result);

      if (fileInputRef?.current) {
        fileInputRef.current.value = null;
      }
      setAudio(null);
      setTemplate(null);
      setCorrectTemplate(null);
    } catch (error) {
      console.error('Error sending data:', error);
    } finally {
      setUploadData(false);
    }
  }

  return (
    <div className="speech-container">
      <div className="csv-file-label">{`File: ${audio?.name ? audio?.name : 'No file'}`}</div>
      <div className="row bg-white">
        <label htmlFor="fileInput" className="btn btn-success w200 ms-2 mt-2 mb-1">
          {isUploadAudioFile ? 'Uploading...' : <><UploadIcon /> Upload audio file</>}
          <input
            id="fileInput"
            type="file"
            accept="audio/*"
            style={{ display: 'none' }}
            onChange={handleFileChange}
            ref={fileInputRef}
          />
        </label>
      </div>

      <div className="bot-select textarea-demo-call mt-1">
        <TextareaField
          label="Transcription: "
          name="data"
          value={correctTemplate ? correctTemplate : ''}
          onChange={onChangeTemplate}
          minHeight="400px"
          maxHeight="450px"
        />
      </div>
      <div className="mt-2">
        <Button
          variant="primary"
          className="model-button"
          onClick={sendData}
          disabled={!audio || !template || !correctTemplate}
        >
          <SaveFlIcon className="mb-1 mr-2 call-button-icon" /> {isUploadData ? ' Sending...' : ' Send data'}
        </Button>
      </div>
      <br />
      <br />
      <div className="p-0 mb-2">
        <p><InfoIcon /> Upload the audio file, wait for response with the transcription (the text will appear in the Transcription field after the API response)</p>
        <p><InfoIcon /> Important: Don't change number of lines!</p>
        <p><InfoIcon /> Check the text, if there are any errors, correct them and click the "Send data" button.</p>
      </div>
    </div>
  );
};

export default STTComponent;
