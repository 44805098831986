import React, { useState, useRef } from 'react';
import { Button } from 'react-bootstrap';
import { SaveFlIcon, InfoIcon, GetAudioIcon, VocalIcon } from '../../common/icons';
import TextareaField from '../../common/forms/textarea.form';

const TTSComponent = () => {
  const [text, setText] = useState('');
  const [audioSrc, setAudioSrc] = useState(null);
  const [userAudioSrc, setUserAudioSrc] = useState(null);
  const [isGettingAudio, setIsGettingAudio] = useState(false);
  const [isUploadingData, setIsUploadingData] = useState(false);
  const [isRecording, setIsRecording] = useState(false);

  const audioPlayerRef = useRef(null);
  const userAudioPlayerRef = useRef(null);
  const mediaRecorderRef = useRef(null);
  const recordedChunksRef = useRef([]);

  const onChangeText = (e) => {
    setText(e.target.value);
    setAudioSrc(null);
    setUserAudioSrc(null);
  };

  const getAudio = async () => {
    setAudioSrc(null);
    try {
      setIsGettingAudio(true);
      if (!text) {
        console.error('No text');
        return;
      }

      const response = await fetch('https://callolive.com/v1.0/training/tts/get-audio', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ text }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error: ${response.status}`);
      }

      const blob = await response.blob();
      const url = URL.createObjectURL(blob);
      setAudioSrc(url);
    } catch (error) {
      console.error('Error fetching audio:', error);
    } finally {
      setIsGettingAudio(false);
    }
  };

  const startRecording = () => {
    setIsRecording(true);
    setUserAudioSrc(null);
    recordedChunksRef.current = [];
    navigator.mediaDevices.getUserMedia({ audio: true })
      .then((stream) => {
        mediaRecorderRef.current = new MediaRecorder(stream);
        mediaRecorderRef.current.start();

        mediaRecorderRef.current.ondataavailable = (event) => {
          if (event.data.size > 0) {
            recordedChunksRef.current.push(event.data);
          }
        };

        mediaRecorderRef.current.onstop = () => {
          const blob = new Blob(recordedChunksRef.current, { type: 'audio/wav' });
          const url = URL.createObjectURL(blob);
          setUserAudioSrc(url);

          if (userAudioPlayerRef.current) {
            userAudioPlayerRef.current.load();
          }
        };
      })
      .catch((err) => {
        console.error('Error accessing microphone:', err);
        setIsRecording(false);
      });
  };

  const stopRecording = () => {
    setIsRecording(false);
    mediaRecorderRef.current.stop();
  };

  const sendData = async () => {
    try {
      setIsUploadingData(true);
      if (!text?.length || !audioSrc) {
        console.error('Incomplete data.');
        return;
      }

      const formData = new FormData();
      formData.append('text', text);

      if (userAudioSrc) {
        const response = await fetch(userAudioSrc);
        const blob = await response.blob();
        formData.append('audio_file', blob);
      } else {
        const response = await fetch(audioSrc);
        const blob = await response.blob();
        formData.append('audio_file', blob);
      }

      const response = await fetch('https://callolive.com/v1.0/training/tts/save-files', {
        method: 'POST',
        body: formData,
      });

      if (!response.ok) {
        throw new Error(`HTTP error: ${response.status}`);
      }

      const result = await response.json();
      console.log('Data successfully sent:', result);

      setAudioSrc(null);
      setUserAudioSrc(null);
      setText('');
    } catch (error) {
      console.error('Error sending data:', error);
    } finally {
      setIsUploadingData(false);
    }
  };

  return (
    <div className="speech-container">
      <div className="bot-select textarea-demo-call">
        <TextareaField
          label="Text: "
          name="text"
          value={text}
          rows={1}
          onChange={onChangeText}
          minHeight="50px"
          maxHeight="70px"
        />
      </div>

      <div className="mt-2">
        <Button
          variant="primary"
          className="model-button"
          onClick={getAudio}
          disabled={!text?.length || isGettingAudio}
        >
          <GetAudioIcon className="mb-2 mr-2 call-button-icon" />{isGettingAudio ? 'Getting audio...' : 'Get TTS audio'}
        </Button>
      </div>

      <div className="bot-select mt-2 mb-3">
        <span className='text-muted'>TTS audio: </span>
        <div className={`ms-2 audio-player-container ${!audioSrc ? 'disabled' : ''}`}>
          <audio controls src={audioSrc || ''} ref={audioPlayerRef}></audio>
          {!audioSrc && <div className="overlay"></div>}
        </div>
      </div>

      <div className="mt-2">
        <Button
          variant={!isRecording ? "secondary" : "danger"}
          className="model-button"
          onClick={!isRecording ? startRecording : stopRecording}
          disabled={!audioSrc}
        >
          <VocalIcon className="mb-1 mr-2 call-button-icon" />{!isRecording ? 'Record Your audio' : 'Stop Recording'}
        </Button>
      </div>

      <div className="bot-select mt-2 mb-3">
        <span className='text-muted'>Your audio: </span>
        <div className={`ms-2 audio-player-container ${(!userAudioSrc || isRecording) ? 'disabled' : ''}`}>
          <audio key={userAudioSrc} controls src={userAudioSrc || ''} ref={userAudioPlayerRef}></audio>
          {(!userAudioSrc || isRecording) && <div className="overlay"></div>}
        </div>
      </div>

      <div className="mt-2">
        <Button
          variant="primary"
          className="model-button"
          onClick={sendData}
          disabled={isUploadingData || !audioSrc}
        >
          <SaveFlIcon className="mb-1 mr-2 call-button-icon" /> {isUploadingData ? 'Sending...' : 'Send data'}
        </Button>
      </div>

      <br />
      <br />
      <br />
      <div className="p-0 mb-2">
        <p><InfoIcon /> Enter the text and click the "Get TTS data" button. After receiving the audio, listen to it - if everything is fine (no pronunciation or intonation errors) - click the "Send data" button</p>
        <p><InfoIcon /> If there are errors in the audio from TTS, click on the "Record Your audio" button and record the audio with correct pronunciation and intonation, after this - click the "Send data" button</p>
        <p><InfoIcon /> The text and one of the audio will be sent to the server. If the model returned the correct audio - it will be sent, if you recorded the audio - the recorded audio will be sent.</p>
      </div>
    </div>
  );
};

export default TTSComponent;
