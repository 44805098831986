import React from 'react';
import currentUser from '../../HOCs/currentUser.hoc';
import IncomingCallComponent from './incomingCall.component';

const IncomingCallPage = () => {
  return (
    <div className="main-content mt-5">
      <div className="row text-muted"><h3>Imcoming Call Settings</h3></div>
      <div className="row bg-white p-2 h90p">
        <IncomingCallComponent />
      </div>
      <div className="empty-bottom-block" />
    </div>
  );
};

export default currentUser(IncomingCallPage);
