import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { SettingsIcon, InfoIcon } from '../../common/icons';
import Select from 'react-select';
import {
  useGetConvPathwayQuery,
  useGetBlandNumberDataQuery,
  useUpdateIncomingDataMutation
} from '../../services/callApi';
import { useGetTemplatesListQuery } from '../../services/templateApi';

const IncomingCallComponent = () => {

  const [convPathways, setConvPathways] = useState([]);
  const [phoneData, setPhoneData] = useState({});
  const [template, setTemplate] = useState({});
  const [convPathway, setConvPathway] = useState({ id: null, title: 'not selected' });
  const [record, setRecord] = useState(true);
  const [language, setLanguage] = useState('');
  const [voice, setVoice] = useState('');
  const [error, setError] = useState(false);

  const [updateIncomingData, { isLoading: isLoadingUpdate, isError }] = useUpdateIncomingDataMutation();
  const { data: convData } = useGetConvPathwayQuery(null, { refetchOnMountOrArgChange: true });
  const { data: templates } = useGetTemplatesListQuery(null, { refetchOnMountOrArgChange: true });
  const { data: incomingPhone, isLoading, refetch } = useGetBlandNumberDataQuery(null, { refetchOnMountOrArgChange: true });

  useEffect(() => {
    if (templates?.length && Object.keys(template).length === 0) {
      setTemplate(templates[0]);
    }
  }, [templates, template]);

  useEffect(() => {
    if (convData) {
      setConvPathways([{ id: null, title: 'not selected' }, ...convData]);
    }
  }, [convData]);

  useEffect(() => {
    if (incomingPhone && convPathways) {
      setPhoneData(incomingPhone);
      setRecord(incomingPhone?.record);
      setLanguage(incomingPhone?.language);
      setVoice(incomingPhone?.voice);
      if (incomingPhone?.pathway_id) {
        const convPathway = convPathways.find(item => item.id === incomingPhone?.pathway_id)
        if (!!incomingPhone?.dynamic_data) {
          convPathway.dynamic_data = incomingPhone?.dynamic_data;
        }
        setConvPathway(convPathway);
      }
    }
  }, [incomingPhone, convPathways]);


  const onChangeTemplate = (e) => {
    const { value } = e.target;
    const { id } = e.target.selectedOptions[0];
    onChangeConvPathway({ target: { selectedOptions: [{ id: null }] } });
    setTemplate({ id: id, title: value });
  }

  const onChangeConvPathway = (e) => {
    const { id } = e.target.selectedOptions[0];
    const obj = convPathways?.find(item => item?.id === (id || null));
    setConvPathway(obj);
  }

  const onChangeDynamicFieldForConv = (fieldName, newValue) => {
    setConvPathway((prev) => ({
      ...prev,
      dynamic_data: {
        ...prev.dynamic_data,
        [fieldName]: newValue
      }
    }));
  };

  const handleUpdate = async () => {
    try {
      setError(null);
      const response = await updateIncomingData({
        pathway_id: convPathway?.id,
        dynamic_data: convPathway?.convPathway,
        language,
        voice,
        record,
        template_id: template?.id,
      });
  
      if (response?.error) {
        if (response?.error?.data?.error) {
          setError(response?.error?.data?.error);
        } else {
          setError('Something went wrong, please try again later or contact support.');
        }
        return;
      }
      await refetch();
    } catch (err) {
      setError('Something went wrong, please try again later or contact support.');
    }
  }

  if (isLoading) return (
    <div className="speech-container">
      Loading ...
    </div>
  )

  return (
    <div className="speech-container">
      <div className="bot-select mt-3 mb-3">
        <span>Phone: </span>{phoneData?.phone_number}
      </div>
      <div className="bot-select mb-3">
        <span>Template:</span>
        <select className="form-select w243 bot-select ms-2" onChange={onChangeTemplate}>
          {templates?.map(temp => (
            <option key={temp.id} id={temp.id} value={temp.title}>
              {temp.title}
            </option>
          ))}
        </select>
      </div>
      <div className="mb-3">
        <span>Conversational Pathways:</span><br />
        <select
          className="form-select bot-select ms-6"
          onChange={onChangeConvPathway}
          value={convPathway?.title}
          style={{ width: '246px' }}
        >
          {convPathways?.map(convP => (
            <option key={convP.id} id={convP.id} value={convP.title}>
              {convP.title}
            </option>
          ))}
        </select>
      </div>
      {convPathway?.dynamic_data && Object.keys(convPathway?.dynamic_data)?.length ? (
        <div className="mb-3">
          <span>Dynamic fields:</span>
          <br />
          <div className="w400">
            <hr />
            {Object.keys(convPathway?.dynamic_data)?.map((fieldKey) => (
              <div className="bot-select ms-2 mb-3" key={fieldKey}>
                <span>{fieldKey}: </span>
                <input
                  type="text"
                  className="form-control ms-2 mt-1"
                  value={convPathway.dynamic_data?.[fieldKey] || ''}
                  onChange={(e) => onChangeDynamicFieldForConv(fieldKey, e.target.value)}
                />
              </div>
            ))}
            <hr />
          </div>
        </div>
      ) : null}
      <div className="bot-select mb-3">
        <span>Language:</span><br />
        <input
          type="text"
          className="form-control ms-2 mt-1"
          value={language || ''}
          onChange={(e) => { setLanguage(e.target.value) }}
          style={{ width: '239px' }}
        />
      </div>
      <div className="bot-select mb-3">
        <span>Voice:</span><br />
        <input
          type="text"
          className="form-control ms-2 mt-1"
          value={voice || ''}
          onChange={(e) => { setVoice(e.target.value) }}
          style={{ width: '272px' }}
        />
      </div>
      <div className="bot-select mb-3">
        <span>Record:</span><br />
        <div className="form-check form-switch">
          <input
            className="form-check-input"
            type="checkbox"
            id="flexSwitchCheckChecked"
            checked={record}
            style={{ "marginTop": '6px', }}
            onChange={(e) => { setRecord(e.target.checked) }}
          />
        </div>
      </div>
      <div className=" mt-1">
        <Button
          variant="primary"
          className="call-button"
          onClick={handleUpdate}
        >
          <SettingsIcon /> {isLoadingUpdate ? 'Updating...' : 'Send updates'}
        </Button>
      </div>
      <div className="empty-block alert-text mt-1">{error ?`Error: ${error}` : null}</div>
      <br />
      <div className="call-info-block p-0 mb-2">
        <p><InfoIcon /> This is the incoming call settings page. It is only visible to users who have the word "incoming" in their email.</p>
        <p><InfoIcon /> You can select Template or Conversational Pathways. If you select a Conversational Pathways, it will override the Template field.</p>
        <p><InfoIcon /> You can put the following values in the Language field: en, auto, en-US, en-AU, es, fr, de, it, de.</p>
        <p><InfoIcon /> To change a voice, copy the voice ID from the "Voices" page and paste it into the text field. After saving, the voice name will be displayed.</p>
        <p><InfoIcon /> You can turn on/off call recording.</p>
      </div>
    </div>
  );
};

export default IncomingCallComponent;
