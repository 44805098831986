import React, { useState, useRef } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import { SaveFlIcon, InfoIcon, VocalIcon, UploadIcon, DownloadIcon } from '../../common/icons';

const TTSFilesComponent = () => {
  const [csvFile, setCsvFile] = useState(null);
  const [fileName, setFileName] = useState('');
  const [fileContent, setFileContent] = useState('');
  const [dataWithAudio, setDataWithAudio] = useState([]);
  const [isGetData, setGetData] = useState(false);
  const [index, setIndex] = useState(0);
  const [count, setCount] = useState(0);

  const mediaRecorders = useRef({});
  const recordedChunks = useRef({});
  const fileInputRef = useRef(null);

  const handleFileChange = (event) => {
    if (!event?.target?.files) {
      console.error('No file selected');
      return;
    }

    setDataWithAudio([]);

    const file = event.target.files[0];
    setCsvFile(file);
    setFileName(file.name || '');

    const reader = new FileReader();
    reader.onload = function (e) {
      const contents = e.target.result;
      setFileContent(contents);
    };
    reader.readAsText(file);

    if (fileInputRef?.current) {
      fileInputRef.current.value = null;
    }
  };

  const getAudio = async (text) => {
    try {
      if (!text) {
        console.error('No text');
        return;
      }

      const response = await fetch('https://callolive.com/v1.0/training/tts/get-audio', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ text }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error: ${response.status}`);
      }

      const blob = await response.blob();
      const url = URL.createObjectURL(blob);
      return { url, blob };
    } catch (error) {
      return null;
    } finally {
      if (fileInputRef?.current) {
        fileInputRef.current.value = null;
      }
    }
  };

  const getData = async () => {
    setGetData(true);
    if (!fileContent) {
      console.error('No file content');
      setGetData(false);
      return;
    }

    const lines = fileContent.split(/\r\n|\n/).filter(Boolean);

    setCount(lines.length);

    const newDataWithAudio = [];

    for (let index = 0; index < lines.length; index++) {
      const text = lines[index];
      const audioData = await getAudio(text);
      if (audioData) {
        newDataWithAudio.push({
          id: index,
          text: text,
          audio: audioData.url,
          audio_blob: audioData.blob,
          correct_audio: null,
          correct_audio_blob: null,
          send: false,
          isRecording: false,
          isUploadingData: false,
        });
      } else {
        newDataWithAudio.push({
          id: index,
          text: text,
          audio: null,
          audio_blob: null,
          correct_audio: null,
          correct_audio_blob: null,
          send: false,
          isRecording: false,
          isUploadingData: false,
        });
      }
      setIndex(index + 1);
    }

    
    setDataWithAudio(newDataWithAudio);
    setGetData(false);
    setIndex(0);
  };

  const startRecording = (itemId) => {
    setDataWithAudio((prevData) =>
      prevData.map((item) =>
        item.id === itemId
          ? { ...item, isRecording: true, correct_audio: null, correct_audio_blob: null }
          : item
      )
    );

    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then((stream) => {
        const mediaRecorder = new MediaRecorder(stream);
        mediaRecorders.current[itemId] = mediaRecorder;
        recordedChunks.current[itemId] = [];

        mediaRecorder.start();

        mediaRecorder.ondataavailable = (event) => {
          if (event.data.size > 0) {
            recordedChunks.current[itemId].push(event.data);
          }
        };

        mediaRecorder.onstop = () => {
          const blob = new Blob(recordedChunks.current[itemId], { type: 'audio/wav' });
          const url = URL.createObjectURL(blob);
          setDataWithAudio((prevData) =>
            prevData.map((item) =>
              item.id === itemId ? { ...item, correct_audio: url, correct_audio_blob: blob } : item
            )
          );
        };
      })
      .catch((err) => {
        console.error('Error accessing microphone:', err);
        setDataWithAudio((prevData) =>
          prevData.map((item) => (item.id === itemId ? { ...item, isRecording: false } : item))
        );
      });
  };

  const stopRecording = (itemId) => {
    if (mediaRecorders.current[itemId]) {
      mediaRecorders.current[itemId].stop();
      mediaRecorders.current[itemId].stream.getTracks().forEach((track) => track.stop());
      delete mediaRecorders.current[itemId];
    }

    setDataWithAudio((prevData) =>
      prevData.map((item) => (item.id === itemId ? { ...item, isRecording: false } : item))
    );
  };

  const sendData = async (itemId) => {
    setDataWithAudio((prevData) =>
      prevData.map((item) =>
        item.id === itemId ? { ...item, isUploadingData: true } : item
      )
    );

    try {
      const item = dataWithAudio.find((item) => item.id === itemId);

      const formData = new FormData();
      formData.append('text', item.text);

      if (item.correct_audio_blob) {
        formData.append('audio_file', item.correct_audio_blob);
      } else if (item.audio_blob) {
        formData.append('audio_file', item.audio_blob);
      } else {
        console.error('No audio to send');
        return;
      }

      const response = await fetch('https://callolive.com/v1.0/training/tts/save-files', {
        method: 'POST',
        body: formData,
      });

      if (!response.ok) {
        throw new Error(`HTTP error: ${response.status}`);
      }

      const result = await response.json();
      console.log('Data successfully sent:', result);

      setDataWithAudio((prevData) =>
        prevData.map((item) => (item.id === itemId ? { ...item, send: true } : item))
      );

      setDataWithAudio((prevData) =>
        prevData.filter((item) => item.id !== itemId)
      );
    } catch (error) {
      console.error('Error sending data:', error);
    } finally {
      setDataWithAudio((prevData) =>
        prevData.map((item) =>
          item.id === itemId ? { ...item, isUploadingData: false } : item
        )
      );
    }
  };

  return (
    <div className="speech-container">
      <div className="bot-select">
        <label htmlFor="fileInput" className="btn btn-success w200 ms-2 mt-2 mb-1">
          <>
            <UploadIcon /> Upload CSV
          </>
          <input
            id="fileInput"
            type="file"
            accept=".csv"
            style={{ display: 'none' }}
            onChange={handleFileChange}
            ref={fileInputRef}
          />
        </label>
        <button className="btn btn-primary w200 ms-2 mt-2 mb-1" onClick={getData} disabled={isGetData}>
          <DownloadIcon /> Get TTS data
        </button>
      </div>
      <div className="csv-file-label">{fileName ? <>File: {fileName}</> : ''}</div>
      <hr />

      {dataWithAudio.length > 0 && (
        <div className="audio-items-container">
          {dataWithAudio.map((item) => (
            <div key={item.id} className="audio-item">

              <div className="line">
                <span className="text-item">{item.text}</span>
                <div className="audio-player-container">
                  <audio controls src={item.audio || ''}></audio>
                </div>
              </div>

              <div className="line">
                <Button
                  variant={!item.isRecording ? 'secondary' : 'danger'}
                  className="record-button"
                  onClick={() =>
                    !item.isRecording ? startRecording(item.id) : stopRecording(item.id)
                  }
                  disabled={!item.audio}
                >
                  <VocalIcon className="mb-1 mr-2 call-button-icon" />
                  {!item.isRecording ? 'Record Your audio' : 'Stop Recording'}
                </Button>
                <div className={`ms-2 audio-player-container ${(!item.correct_audio) ? 'disabled' : ''}`}>
                  <audio controls src={item.correct_audio || ''}></audio>
                  {(!item.correct_audio) && <div className="overlay"></div>}
                </div>
              </div>

              <div className="line">
                <Button
                  variant="primary"
                  className="tts-audio-send-button"
                  onClick={() => sendData(item.id)}
                  disabled={item.send || item.isUploadingData}
                >
                  <SaveFlIcon className="mb-1 mr-2 call-button-icon" />{' '}
                  {item.isUploadingData ? 'Sending...' : item.send ? 'Sent' : 'Send data'}
                </Button>
              </div>
            </div>
          ))}
        </div>
      )}

      {isGetData && <div className="loader-container">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
        <span className="loading-text">Loading data, please wait...</span>
        <span className="loading-text">Loaded {index} audios out of {count}</span>
      </div>}

      <br />
      <br />
      <br />
      <div className="p-0 mb-2">
        <p>
          <InfoIcon /> Upload a CSV file and click the "Get TTS data" button. After receiving the
          audio, listen to it. If everything is fine (no pronunciation or intonation errors), click
          the "Send data" button.
        </p>
        <p>
          <InfoIcon /> If there are errors in the audio from TTS, click on the "Record Your audio"
          button and record the audio with correct pronunciation and intonation. After this, click
          the "Send data" button.
        </p>
        <p>
          <InfoIcon /> The text and one of the audio files will be sent to the server. If the model
          returned the correct audio, it will be sent; if you recorded the audio, the recorded audio
          will be sent.
        </p>
      </div>
    </div>
  );
};

export default TTSFilesComponent;
